<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="input-group mb-2">
          <span class="input-group-text" id="basic-addon1"
            ><font-awesome-icon icon="fa-solid fa-magnifying-glass"
          /></span>
          <input
            type="text"
            list="input-list"
            class="form-control"
            placeholder="Cari Berdasarkan Kota"
            v-model="searchTerm"
          />
          <datalist
            id="input-list"
            class="form-select"
            aria-label="Default select example"
          >
            <option v-for="location in locations" v-bind:key="location.name">
              {{ location.name_store }}
            </option>
          </datalist>
        </div>
        <div class="content-card-lokasi" id="card">
          <div
            class="card-lokasi card"
            v-for="location in filteredLocations"
            :key="location.id"
          >
            <div>
              <div class="name-lokasi">{{ location.name_store }}</div>
              <div class="row row-content">
                <div class="col-md-8">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <font-awesome-icon
                            icon="fa-solid fa-location-dot"
                            size="lg"
                          />
                        </td>
                        <td>{{ location.alamat_store }}</td>
                      </tr>
                      <tr>
                        <td>
                          <font-awesome-icon
                            icon="fa-solid fa-phone"
                            size="lg"
                          />
                        </td>
                        <td>0{{ location.whatsapp }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <a :href="location.link" target="_blank" class="link"
                    >Lihat Lokasi
                  </a>
                </div>
                <div class="col-md-4">
                  <div class="content-img-lokasi">
                    <img
                      v-img
                      :src="`${BASE_URL}toko/icon/${location.image}`"
                      alt=""
                      class="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div id="map" ref="map"></div>
      </div>
    </div>
  </div>
</template>
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDwst0K_-fAe7v-jleuEp9SP_KZ2HNqi0k&callback=initMap"></script>
<script>
import { BASE_URL } from "@/config.js";
import api from "@/api";
export default {
  name: "LokasiComponent",

  data() {
    return {
      locations: [],
      searchTerm: "",
      activeInfoWindow: null,
      BASE_URL,
      key: "AIzaSyDwst0K_-fAe7v-jleuEp9SP_KZ2HNqi0k",
    };
  },
  computed: {
    filteredLocations() {
      return this.locations.filter((location) => {
        return location.name_store
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase());
      });
    },
  },
  mounted() {
    this.fetchLocations();
  },
  methods: {
    async fetchLocations() {
      try {
        const response = await api.get("Content/toko");
        this.locations = response.data["data"];
        this.initMap();
      } catch (error) {
        // console.error(error);
      }
    },
    initMap() {
      if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.key}&callback=initMap`;
        script.defer = true;
        script.async = true;
        document.head.appendChild(script);
        window.initMap = this.initMapCallback;
      } else {
        this.initMapCallback();
      }
    },
    initMapCallback() {
      const map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: -6.5252743933606, lng: 107.45788316879054 },
        zoom: 6,
      });

      this.locations.forEach((location) => {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(location.lat, location.lng),
          map: map,
          title: location.name_store,
        });
        let infoWindow = new google.maps.InfoWindow({
          content: `<h5>${location.name_store}</h5> <p>${location.alamat_store}</p> <a href='${location.link}' target='_blank'> Lihat Lokasi</a>`,
        });
        google.maps.event.addListener(infoWindow, "closeclick", () => {
          map.setZoom(6);
        });

        marker.addListener("click", () => {
          if (this.activeInfoWindow) {
            this.activeInfoWindow.close();
          }
          infoWindow.open(map, marker);
          this.activeInfoWindow = infoWindow;
          map.setZoom(9);
          map.setCenter(marker.getPosition());
        });
      });
    },
  },
};
</script>
<style></style>
