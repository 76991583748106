<template>
  <div>
    <section>
      <div class="container">
        <div class="header-estimasi">
          <p class="title-estimasi">KALKULATOR HARGA</p>
          <p class="desc-estimasi">
            Perbaikan Cepat Oleh Teknisi yang Profesional
          </p>
        </div>
      </div>
    </section>
    <section class="bg-card-estimasi">
      <div class="container">
        <div class="row card-option">
          <div class="col-md-9">
            <div class="card-option-jenis">
              <div class="header">PILIH JENIS PERANGKAT</div>
              <div class="content-icon">
                <div class="row d-flex justify-content-center">
                  <div
                    class="col-md-3 col-6"
                    v-for="item in data"
                    :key="item.id"
                    @click="getModel(item)"
                  >
                    <div class="cards">
                      <div class="option">
                        <img
                          :src="`${BASE_URL}calculate/kategori/${item.image}`"
                          alt=""
                          @error="handleImageError"
                        />
                        <p>{{ item.name_kategori }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-4">
            <div>
              <div v-if="showResult">
                <select
                  class="form-select"
                  v-if="modelList.length"
                  v-model="selectedTypeModel"
                  style="width: 100%"
                  @change="getList"
                >
                  <option value="" disabled selected>
                    Select Type {{ selectedModel.name_kategori }}
                  </option>
                  <option
                    v-for="model in modelList"
                    :key="model.id"
                    :value="model.id"
                  >
                    {{ model.name_model }}
                  </option>
                </select>
              </div>
              <div v-if="notFound">
                <select class="form-select" disabled>
                  <option value="">Not Found</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 content-crash">
          <div class="col-md-4" v-if="selectedImageModel">
            <img
              :src="`${BASE_URL}calculate/model/${this.selectedImageModel}`"
              alt=""
            />
          </div>
          <div class="col-md-8">
            <div
              class="row checkbox-crash kerusakan-list"
              v-if="selectedTypeModel"
            >
              <div
                class="col-md-6 col-6 kerusakan-item"
                v-for="kerusakan in kerusakans"
                :key="kerusakan.nama"
              >
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="selectedKerusakan"
                    :value="kerusakan"
                  />
                  <label class="form-check-label">
                    {{ kerusakan.name_list }}
                  </label>
                </div>
              </div>
            </div>
            <div v-if="notKerusakan">
              <div class="alert alert-danger" role="alert">Not Found!</div>
            </div>
            <div class="estimasi-harga" v-if="selectedTypeModel">
              <div class="row">
                <div
                  class="col-md-6 total-harga"
                  v-if="selectedKerusakan.length > 0"
                >
                  <div class="header">ESTIMASI HARGA</div>
                  <div class="jumlah-estimasi">
                    <span>{{
                      totalHarga.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { BASE_URL } from "@/config.js";
import api from "@/api";
export default {
  name: "CalculateComponent",
  data() {
    return {
      data: [],
      modelList: [],
      selectedImageModel: "",
      BASE_URL,
      kerusakans: [],
      selectedKerusakan: [],
      selectedTypeModel: "",
      showResult: false,
      notFound: false,
      notKerusakan: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleImageError(event) {
      event.target.src = require("@/assets/img/Monster 404 Error-bro.png");
    },
    async getData() {
      try {
        const response = await api.get("Content/kategori");
        this.data = response.data["data"];
      } catch (error) {
        // console.error(error);
      }
    },
    async getModel(item) {
      // this.loading = true;
      try {
        const response = await api.get(`Content/model?id=${item.id}`);
        if (response.data["status"] == 200) {
          this.modelList = response.data["data"];
          this.selectedImageModel = "";
          this.selectedTypeModel = "";
          this.showResult = true;
          this.notFound = false;
          this.notKerusakan = false;
          this.selectedModel = item;
        } else {
          this.selectedTypeModel = "";
          this.selectedImageModel = "";
          this.kerusakans = [];
          this.notFound = true;
          this.showResult = false;
          this.notKerusakan = false;
          this.selectedModel = item;
        }
      } catch (error) {
        // console.error(error);
      }
    },
    async getList() {
      try {
        const response = await api.get(
          `Content/list?id=${this.selectedTypeModel}`
        );
        if (response.data["status"] == 200) {
          this.kerusakans = response.data["data"];
          this.notKerusakan = false;
        } else {
          this.notKerusakan = true;
          this.kerusakans = [];
        }
      } catch (error) {
        // console.error(error);
      }
    },
  },
  computed: {
    totalHarga() {
      return this.selectedKerusakan.reduce((total, kerusakan) => {
        return total + Number(kerusakan.price);
      }, 0);
    },
  },
  watch: {
    selectedTypeModel() {
      const model = this.modelList.find(
        (item) => item.id === this.selectedTypeModel
      );
      if (model) {
        this.selectedImageModel = model.image;
        this.selectedKerusakan = [];
      }
    },
  },
};
</script>

<style></style>
