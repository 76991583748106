<template>
  <div>
    <div class="content-form">
      <div class="header">FORMULIR GO SERVICE</div>
      <div class="mb-3 input-with-counter">
        <label for="nama" class="form-label">Nama Lengkap</label>
        <input
          type="text"
          class="form-control"
          id="nama"
          placeholder="Masukkan nama lengkap anda"
          minlength="3"
          maxlength="50"
          required
          v-model="nama"
          @input="updateCounter"
        />
        <div class="character-counter">{{ nama.length }} / 50</div>
      </div>
      <div class="mb-3 input-with-counter">
        <label for="email">Email </label>
        <input
          type="email"
          id="email"
          v-model="email"
          class="form-control"
          placeholder="Contoh : Alexander@gmail.com"
          maxlength="50"
          required
          @input="updateCounter"
        />
        <div class="character-counter">{{ email.length }} / 50</div>
      </div>
      <div class="mb-3 input-with-counter">
        <label for="no" class="form-label">No Telepon (Whatsapp) </label>
        <input
          type="text"
          class="form-control"
          id="telepon"
          placeholder="Contoh : 08XXX / 62XXX"
          required
          v-model="telepon"
          maxlength="20"
          @input="updateCounter"
        />
        <div class="character-counter">{{ telepon.length }} / 14</div>
      </div>
      <div class="mb-3 input-with-counter">
        <label for="model" class="form-label">Model </label>
        <input
          type="text"
          class="form-control"
          id="model"
          placeholder="Contoh: Iphone XR "
          required
          v-model="model"
          maxlength="50"
          @input="updateCounter"
        />
        <div class="character-counter">{{ model.length }} / 50</div>
      </div>
      <div class="mb-3 input-with-counter">
        <label for="keluhan" class="form-label">Keluhan </label>
        <input
          type="text"
          class="form-control"
          id="keluhan"
          placeholder="Deskripsi Kerusakan"
          required
          v-model="keluhan"
          maxlength="250"
          @input="updateCounter"
        />
        <div class="character-counter">{{ keluhan.length }} / 250</div>
      </div>
      <div class="mb-3">
        <label for="alamat" class="form-label">Alamat Lengkap</label>
        <textarea
          class="form-control"
          placeholder="Contoh: Jalan Sudirman no.17"
          id="alamat"
          rows="2"
          required
          v-model="alamat"
        ></textarea>
      </div>
      <div class="btn-form mb-3">
        <button
          type="submit"
          v-if="disabled"
          @click.prevent="save"
          class="btn btn-primary btn-lg"
          :disabled="isButtonDisabled"
        >
          Mohon Tunggu Beberapa Saat
        </button>
        <button
          type="submit"
          v-else
          @click.prevent="save"
          class="btn btn-primary btn-lg"
        >
          KIRIM
        </button>
      </div>
    </div>
    <!-- <div v-if="loading" class="text-center mt-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import api from "@/api";
export default {
  name: "FormServiceComponent",
  data() {
    return {
      nama: "",
      email: "",
      telepon: "",
      model: "",
      keluhan: "",
      alamat: "",
      // loading: false,
      isButtonDisabled: false,
      characterCount: 0,
      disabled: false,
    };
  },

  methods: {
    updateCounter() {
      this.telepon = this.telepon.replace(/\D/g, "");
    },
    async save() {
      if (
        !this.nama ||
        !this.email ||
        !this.telepon ||
        !this.model ||
        !this.keluhan ||
        !this.alamat
      ) {
        Swal.fire({
          title: "Error!",
          text: "Semua data harus diisi",
          icon: "error",
          iconColor: "#bf3c3c",
          color: "#fff",
          background: " #00000080",
          confirmButtonColor: "#bf3c3c",
          confirmButtonText: "OK",
        });
        return;
      }
      if (this.nama.length < 3 || this.nama.length > 50) {
        Swal.fire({
          title: "Error!",
          text: "Nama minimal 3 karakter dan maksimal 50 karakter",
          icon: "error",
          iconColor: "#bf3c3c",
          color: "#fff",
          background: " #00000080",
          confirmButtonColor: "#bf3c3c",
          confirmButtonText: "OK",
        });
        return;
      }
      if (!this.email.includes("@")) {
        Swal.fire({
          title: "Error!",
          text: "Email harus mengandung @",
          icon: "error",
          iconColor: "#bf3c3c",
          color: "#fff",
          background: " #00000080",
          confirmButtonColor: "#bf3c3c",
          confirmButtonText: "OK",
        });
        return;
      }

      if (!this.telepon.match(/^(08|62)\d+$/)) {
        Swal.fire({
          title: "Error!",
          text: "Gunakan nomor telepon yang valid",
          icon: "error",
          iconColor: "#bf3c3c",
          color: "#fff",
          background: " #00000080",
          confirmButtonColor: "#bf3c3c",
          confirmButtonText: "OK",
        });
        return;
      }

      if (isNaN(this.telepon)) {
        Swal.fire({
          title: "Error!",
          text: "Gunakan nomor telepon yang valid",
          icon: "error",
          iconColor: "#bf3c3c",
          color: "#fff",
          background: " #00000080",
          confirmButtonColor: "#bf3c3c",
          confirmButtonText: "OK",
        });
        return;
      }

      var postData = {
        nama: this.nama,
        email: this.email,
        phone: this.telepon,
        model: this.model,
        keluhan: this.keluhan,
        alamat: this.alamat,
      };
      // this.loading = true;

      try {
        this.disabled = true;
        this.isButtonDisabled = true;
        const response = await api.post("Email/send", postData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        if (response.data["status"] !== 200) {
          Swal.fire({
            title: "Error!",
            text: `Terjadi kesalahan: ${response.data[
              "message"
            ].toUpperCase()}, Silahkan coba lagi nanti. `,
            icon: "error",
            iconColor: "#bf3c3c",
            color: "#fff",
            background: " #00000080",
            confirmButtonColor: "#bf3c3c",
            confirmButtonText: "OK",
          });
          this.loading = false;
          this.nama = "";
          this.email = "";
          this.telepon = "";
          this.model = "";
          this.keluhan = "";
          this.alamat = "";
          return;
        }
        Swal.fire({
          title: "Terimasih sudah menggunakan layanan Go Service!",
          html: "Silahkan cek email anda dari kami, dan ikuti tautan verifikasi yang disertakan di dalamnya.",
          icon: "success",
          iconColor: "#00985b",
          color: "#000",
          background: " #ffffff",
          confirmButtonColor: "#00985b",
          confirmButtonText: "OK",
        });
        // this.loading = false;
        this.nama = "";
        this.email = "";
        this.telepon = "";
        this.model = "";
        this.keluhan = "";
        this.alamat = "";
      } catch (error) {
        // console.error(error);
      } finally {
        this.isButtonDisabled = false;
        this.disabled = false;
      }
    },
  },
};
</script>

<style></style>
