<template>
  <div class="lokasi">
    <section class="lokasi">
      <div class="container">
        <div class="content-lokasi">
          <maps-lokasi-component />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import MapsLokasiComponent from "@/components/MapsLokasiComponent.vue";
export default {
  name: "LokasiView",
  components: {
    MapsLokasiComponent,
  },
};
</script>
