<template>
  <div id="app">
    <navbar-component v-if="isNavbarVisible" />
    <router-view />
    <loading-screen-component />
    <popup-component v-if="isPopupVisible" />
    <footer-component v-if="isFooterVisible" />
  </div>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import PopupComponent from "./components/PopupComponent.vue";
import LoadingScreenComponent from "./components/LoadingScreenComponent.vue";

export default {
  name: "HomeView",
  components: {
    NavbarComponent,
    FooterComponent,
    PopupComponent,
    LoadingScreenComponent,
  },
  data() {
    return {
      refreshing: false,
      isNavbarVisible: true,
      isPopupVisible: true,
      isFooterVisible: true,
    };
  },
  mounted() {
    window.addEventListener("beforeunload", () => {
      this.refreshing = true;
    });
    this.$root.$on("ajax-start", () => {
      this.loading = true;
    });
    this.$root.$on("ajax-stop", () => {
      this.loading = false;
    });
  },
  created() {
    this.hideComponentsIfNeeded();
  },
  methods: {
    hideComponentsIfNeeded() {
      const currentRouteName = this.$route.name;
      if (currentRouteName === "not") {
        // ganti "404" dengan nama rute fallback Anda
        this.isNavbarVisible = false;
        this.isPopupVisible = false;
        this.isFooterVisible = false;
      }
    },
  },
};
</script>
