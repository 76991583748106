<template>
  <section class="data-service">
    <div class="container">
      <div class="content-data">
        <div class="row">
          <div class="col-md-6 col-img">
            <img src="../assets/img/LOGO SFI_1.png" alt="" />
          </div>
          <div class="col-md-6 col-text">
            <div class="col-content gap-3">
              <span class="text-data">
                Jumlah perbaikan yang <br />
                telah kami tangani sampai saat ini
              </span>
              <span class="jumlah-data">{{ showDataCount }}</span>
              <!-- <span class="jumlah-data">3k</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import api from "@/api";
import { BASE_URL } from "@/config";
export default {
  name: "CountServiceComponent",
  data() {
    return {
      dataCount: 0,
      count: 0,
      BASE_URL,
    };
  },
  computed: {
    showDataCount() {
      if (this.dataCount <= 3000) {
        return "3k++";
      } else {
        return this.dataCount.toLocaleString();
      }
    },
  },
  created() {
    this.fetchDataCount();
  },
  methods: {
    async fetchDataCount() {
      try {
        const response = await api.get("Content/serviceDone");
        this.dataCount = response.data["data"].total;
      } catch (error) {
        // console.error("Error fetching data count:", error);
      }
    },
  },
};
</script>

<style></style>
