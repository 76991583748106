<template>
  <nav
    class="navbar navbar-expand-lg sticky-top"
    :class="{ 'navbar-scrolled': isNavbarScrolled }"
  >
    <div class="container">
      <router-link class="navbar-brand" to="/"
        ><img src="../assets/img/logoblack.png" alt=""
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#menuItems"
        aria-controls="menuItems"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="menuItems">
        <ul class="navbar-nav ms-auto gap-3">
          <li class="nav-item">
            <router-link class="nav-link" to="/">
              <b-icon-house></b-icon-house> Home
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/go-service">
              <b-icon-truck></b-icon-truck> Go Service
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/testimony">
              <b-icon-hand-thumbs-up></b-icon-hand-thumbs-up> Testimony
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link" to="/lokasi">
              <b-icon-geo-alt></b-icon-geo-alt> Location
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://shop.sfisolutions.id/"
              ><b-icon-shop-window></b-icon-shop-window> Shop
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { BASE_URL } from "@/config";
export default {
  name: "NavbarComponent",
  data() {
    return {
      isNavbarScrolled: false,
      BASE_URL,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isNavbarScrolled = window.scrollY > 50;
    },
  },
};
</script>

<style></style>
