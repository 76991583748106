<template>
  <div class="home">
    <section class="hero">
      <div class="container">
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-interval="10000"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div
              class="carousel-item"
              v-for="(image, index) in banner"
              :key="index"
              :class="{ active: index === 'image_1' }"
            >
              <img :src="`${BASE_URL}banner/${image}`" />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
    <CekServiceComponent />
    <store-component />
    <CountServiceComponent />
    <layanan-component />
    <CalculateComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import LayananComponent from "@/components/LayananComponent.vue";
import CountServiceComponent from "@/components/CountServiceComponent.vue";
import CalculateComponent from "@/components/CalculateComponent.vue";
import StoreComponent from "@/components/StoreComponent.vue";
import CekServiceComponent from "@/components/CekServiceComponent.vue";
import { BASE_URL } from "@/config.js";
import api from "@/api";

export default {
  name: "HomeView",
  components: {
    LayananComponent,
    CountServiceComponent,
    CalculateComponent,
    StoreComponent,
    CekServiceComponent,
  },
  data() {
    return {
      banner: {},
      BASE_URL,
    };
  },

  created() {
    this.getBanner();
  },
  methods: {
    async getBanner() {
      try {
        const response = await api.get("Content/banner");
        this.banner = response.data["data"];
      } catch (error) {
        // console.error(error);
      }
    },
  },
};
</script>
